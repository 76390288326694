<template>
    <div class="mb-4">
        <b-card class="text-center">
            <h2>
                <font-awesome-icon
                    icon="file-invoice"
                    class="mr-2"
                ></font-awesome-icon>
                Forms
            </h2>
            <div class="mt-3">
                <button
                    class="sc-btn pill"
                    @click="addForm()"
                >
                    <font-awesome-icon icon="plus-circle" class="mr-1"></font-awesome-icon>
                    Add Form
                </button>
            </div>
        </b-card>
        <base-list
            class="mt-2"
            list-url="/api/forms/admin/list"
            delete-url="/api/forms/admin/remove"
            :can-delete="(form) => form.permissions.includes('REMOVE')"
            itemsKey="forms"
            @loaded="setPermissions"
        >
            <template #content="{ item: form }">
                <router-link
                    v-if="!form.isOwner"
                    class="float-right"
                    :to="{ name: 'change_form_settings', params: { id: form.id } }"
                >
                    <font-awesome-icon icon="info-circle"></font-awesome-icon>
                </router-link>
                <h6>
                    <router-link
                        :to="{ name: 'edit_form', params: { id: form.id }}"
                    >
                        {{ form.title }}
                    </router-link>
                </h6>
                <p>{{ form.description || "No description." }}</p>
            </template>
            <template #actions="{ item: form }">
                <div
                    v-if="form.permissions.includes('VIEW_SETTINGS')"
                    class="sc-tooltip"
                >
                    <router-link
                        
                        is="b-button"
                        variant="link"
                        class="mr-2"
                        :to="{ name: 'change_form_settings', params: { id: form.id } }"
                    >
                        <font-awesome-icon icon="cog"></font-awesome-icon>
                    </router-link>
                    <span class="tooltiptext">Settings</span>
                </div>
                <div
                    v-if="form.permissions.includes('VIEW_RESPONSES')"
                    class="sc-tooltip"
                >
                    <router-link
                        is="b-button"
                        variant="link"
                        class="mr-2"
                        :to="{ name: 'get_form_responses', params: { id: form.id } }"
                    >
                        <font-awesome-icon icon="table"></font-awesome-icon>
                        <b-badge
                            variant="danger"
                            class="ml-2"
                        >
                            {{ form.response_count }}
                        </b-badge>
                    </router-link>
                    <span class="tooltiptext">Responses</span>
                </div>
                <div
                    class="sc-tooltip"
                >
                    <router-link
                        is="b-button"
                        variant="link"
                        class="mr-2"
                        :to="{ name: 'change_form_settings', params: { id: form.id } }"
                    >
                        <font-awesome-icon icon="link" />
                    </router-link>
                    <span class="tooltiptext">Share</span>
                </div>
                <div
                    v-if="form.permissions.includes('EDIT')"
                    class="sc-tooltip"
                >
                    <router-link
                        is="b-button"
                        variant="link"
                        class="mr-2"
                        :to="{ name: 'edit_form', params: { id: form.id }}"
                    >
                        <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
                    </router-link>
                    <span class="tooltiptext">Edit</span>
                </div>
                <div
                    v-if="form.permissions.includes('ASSIGN')"
                    class="sc-tooltip"
                >
                    <router-link
                        is="b-button"
                        variant="link"
                        class="mr-2"
                        :to="{ name: 'grant_resource', params: { resourceKey: `form:${form.id}` }}"
                    >
                        <font-awesome-icon icon="key"></font-awesome-icon>
                    </router-link>
                    <span class="tooltiptext">Resource Access</span>
                </div>
                <div
                    v-if="form.permissions.includes('CLONE')"
                    class="sc-tooltip"
                >
                    <b-button
                        variant="link"
                        class="mr-2"
                        @click="cloneForm(form)"
                    >
                        <font-awesome-icon icon="clone"></font-awesome-icon>
                    </b-button>
                    <span class="tooltiptext">Clone</span>
                </div>
            </template>
        </base-list>
    </div>
</template>
<script>
import BaseList from '@/components/ui/BaseList.vue';

export default {
    components: { BaseList },
    methods: {
        addForm() {
            return this.$api.post("/api/forms/admin/create").then((response) => {
                this.$bvToast.toast(response.data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.$router.push({ name: "change_form_settings", params: { id: response.data.form_id } });
            });
        },
        cloneForm(form) {
            return this.$api.post(
                "/api/forms/admin/clone",
                {
                    id: form.id
                }
            ).then(({ data: { message, form_id } }) => {
                this.$bvToast.toast(message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.$router.push({ name: "change_form_settings", params: { id: form_id } });
            });
        },
        setPermissions(forms) {
            forms.forEach((form) => {
                form.permissions = form.permissions ? form.permissions.split(",") : [];
            });
        }
    }
}
</script>